import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { BbPopup } from '@browsbox-ui';
import BlockPicker from './BlockColorPicker';
import { Gateway } from '../Gateway';

const clearColor = 'transparent'; // equals "select none"

const propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  colors: PropTypes.arrayOf(PropTypes.string).isRequired,
  color: PropTypes.string,
  colorSchemeClass: PropTypes.string,
  colorSchemeClasses: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  showPopup: PropTypes.bool.isRequired,
  setShowPopup: PropTypes.func.isRequired,
};

const defaultProps = {
  className: '',
  children: null,
  color: clearColor,
  colorSchemeClass: '',
};

class BrowsboxContentColorPicker extends Component {
  constructor(props) {
    super(props);
    this.onClose = this.onClose.bind(this);
    this.onClick = this.onClick.bind(this);
    this.onChange = this.onChange.bind(this);

    const colorSchemeClassesNames = Object.keys(props.colorSchemeClasses)
      .map(k => props.colorSchemeClasses[k]);
    let colors;
    let color;
    let useColorSchemeClass;
    if (colorSchemeClassesNames.length !== 0) {
      colors = colorSchemeClassesNames.concat(clearColor);
      color = props.colorSchemeClasses[props.colorSchemeClass] || defaultProps.color;
      useColorSchemeClass = true;
    } else {
      colors = props.colors.concat(clearColor);
      color = props.color === null ? defaultProps.color : props.color;
      useColorSchemeClass = false;
    }
    this.state = {
      color,
      colors,
      useColorSchemeClass,
    };
  }

  componentDidUpdate(prevProps) {
    if ((prevProps.color !== this.props.color) && (this.props.color !== this.state.color)) {
      const color = this.props.color !== '' ? this.props.color : defaultProps.color;
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ color });
    }
  }

  onClose() {
    this.props.setShowPopup(false);
  }

  onClick(evt) {
    evt.preventDefault();

    this.props.setShowPopup(!this.props.showPopup);
  }

  onChange(color) {
    const {
      useColorSchemeClass,
    } = this.state;

    const {
      colorSchemeClasses,
    } = this.props;

    const colorHex = color.hex.toLowerCase();
    this.setState({ color: colorHex });

    if (useColorSchemeClass) {
      if (colorHex === clearColor) {
        this.props.onChange(true, null, useColorSchemeClass);
      } else {
        const colorSchemeClassName = Object.keys(colorSchemeClasses)
          .filter(k => colorSchemeClasses[k].toLowerCase() === colorHex).pop();
        this.props.onChange(false, colorSchemeClassName, useColorSchemeClass);
      }
    } else {
      this.props.onChange(colorHex === clearColor, colorHex, useColorSchemeClass);
    }
  }

  renderColorPicker(tetherTarget) {
    const { showPopup } = this.props;

    const {
      colors,
      color,
    } = this.state;

    if (showPopup) {
      return (
        <Gateway into="popup">
          <BbPopup
            placement="right"
            target={tetherTarget}
            onClose={this.onClose}
            closeOnClickInside={false}
            className="o-bb-popup--colorpicker"
          >
            <BlockPicker
              triangle="hide"
              colors={colors}
              color={color}
              onChange={this.onChange}
            />
          </BbPopup>
        </Gateway>
      );
    }
    return null;
  }

  render() {
    const tetherTarget = 'content-color-picker';
    const {
      className,
      children,
    } = this.props;
    const {
      color,
    } = this.state;

    return (
      <a href="#" onClick={this.onClick} className={className} id="content-color-picker">
        <span className="bb-icon bb-icon--color" style={{ backgroundColor: color }} />
        {children}
        {this.renderColorPicker(tetherTarget)}
      </a>
    );
  }
}

BrowsboxContentColorPicker.propTypes = propTypes;
BrowsboxContentColorPicker.defaultProps = defaultProps;

export default BrowsboxContentColorPicker;
