/* eslint-disable no-await-in-loop,jsx-a11y/mouse-events-have-key-events */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Transition } from 'react-transition-group';
import {
  keys, difference, pick, map, isEqual,
} from 'lodash';
import { DragSource, DropTarget } from 'react-dnd';
import { getEmptyImage } from 'react-dnd-html5-backend';
import {
  containerClass,
  contentPropType,
  moduleOptionsProps,
  moduleDefaultOptionsProps,
  CONTENT_COMPONENT_TYPE,
  CONTENT_SECTION_TYPE,
  DEFAULT_COLUMN_COUNT,
  BUTTON_MODE,
  ContentItemBackground,
} from '@liswood-tache/browsbox-static';
import get from 'lodash/get';
import { DND_MENU_MODULE, DND_CONTENT_MODULE, DND_MENU_SAVED_SECTION } from '../DragAndDrop/dndTypes';
import BrowsboxContentColumn from './ContentColumn';
import BrowsboxContentSectionConfig from './ContentSectionConfig';
import ContentComponents from './ContentComponents';
// eslint-disable-next-line import/no-cycle
import ContentSections from './ContentSections';
import {
  setContentOption, setComponentOption, appendColumnComponents, mergeContentOption,
} from '../../actions/contentConfig';
import { DEFAULT_VIEW } from '../../actions/responsive';
import ContentItemContext from './ContentItemContext';
import i18n from '../../internationalization/i18n';
import ScrollManager from '../../tools/window-scroll-manager';
import { setActiveSection } from '../../actions/content';
import { contentDidDrop } from '../../actions/contentMove';
import AnimatedItemContext from './AnimatedItemContext';
import Icon from '../Icon/Icon';
import { openSaveSection } from '../../actions/savedSections';

const manager = new ScrollManager();

const sourceModule = {
  beginDrag(props) {
    manager.init();
    props.onBeginDrag(props.id);

    return {
      dndSource: props.dndSource,
      dndType: props.dndType,
      id: props.id,
      index: props.index,
      originalIndex: props.index,
    };
  },
  endDrag(props, monitor) {
    manager.destroy();

    const item = monitor.getItem();
    props.onEndDrag(item);
  },
};

const targetModule = {
  hover(props, monitor, component) {
    const dragIndex = monitor.getItem().index;
    const { dndType } = monitor.getItem();
    const { dndSource } = monitor.getItem();
    const hoverIndex = props.index;

    if ([DND_MENU_MODULE, DND_MENU_SAVED_SECTION].includes(dndType)) {
      props.addItem(hoverIndex, monitor.getItem());
      // mark as CARD so the new item is added only once
      monitor.getItem().dndType = DND_CONTENT_MODULE; // eslint-disable-line
      monitor.getItem().index = hoverIndex; // eslint-disable-line
      return;
    }

    // Don't replace items with themselves
    if (dragIndex === hoverIndex) {
      return;
    }

    // Determine rectangle on screen
    const hoverBoundingRect = component.sectionRef.getBoundingClientRect(); // eslint-disable-line

    // Get vertical middle
    const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

    // Determine mouse position
    const clientOffset = monitor.getClientOffset();

    // Get pixels to the top
    const hoverClientY = clientOffset.y - hoverBoundingRect.top;

    // Only perform the move when the mouse has crossed half of the items height
    // When dragging downwards, only move when the cursor is below 50%
    // When dragging upwards, only move when the cursor is above 50%

    // Dragging downwards
    if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
      return;
    }

    // Dragging upwards
    if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
      return;
    }

    // drag and hover from menu list to card list
    if (dndSource === DND_MENU_MODULE) {
      props.moveItem(dragIndex, hoverIndex);
    // hover within card list
    } else {
      // Time to actually perform the action
      props.moveItem(dragIndex, hoverIndex);
    }

    monitor.getItem().index = hoverIndex; // eslint-disable-line
  },
  drop() {
    // `isDroppedIntoContent` will be available to BrowsboxModuleItem if the item is dropped
    // onto the section container.
    return { isDroppedIntoContent: true };
  },
};

const dropTargetCollect = (connectDnd, monitor) => ({
  connectDropTarget: connectDnd.dropTarget(),
  isOver: monitor.isOver(),
  didDrop: monitor.didDrop(),
  itemType: monitor.getItemType(),
});

const dragSourceCollect = (connectDnd, monitor) => ({
  connectDragSource: connectDnd.dragSource(),
  connectDragPreview: connectDnd.dragPreview(),
  dndIsDragging: monitor.isDragging(),
});

@DropTarget([DND_CONTENT_MODULE, DND_MENU_MODULE, DND_MENU_SAVED_SECTION], targetModule, dropTargetCollect)
@DragSource(DND_CONTENT_MODULE, sourceModule, dragSourceCollect)

class BrowsboxContentItem extends Component {
  static preventDefault(evt) {
    evt.preventDefault();
    evt.stopPropagation();
  }

  static propTypes = {
    connectDragPreview: PropTypes.func.isRequired,
    connectDragSource: PropTypes.func.isRequired,
    connectDropTarget: PropTypes.func.isRequired,
    content: contentPropType.isRequired,
    dndIsDragging: PropTypes.bool.isRequired,
    dndSource: PropTypes.string.isRequired, // eslint-disable-line
    dndType: PropTypes.string.isRequired, // eslint-disable-line
    id: PropTypes.number.isRequired, // eslint-disable-line react/no-unused-prop-types
    index: PropTypes.number.isRequired, // eslint-disable-line react/no-unused-prop-types
    isContentLocked: PropTypes.bool,
    isContentUnlockable: PropTypes.bool,
    isLocked: PropTypes.bool.isRequired,
    moduleOptions: moduleOptionsProps,
    module: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
    moveItem: PropTypes.func.isRequired, // eslint-disable-line react/no-unused-prop-types
    onBeginDrag: PropTypes.func.isRequired, // eslint-disable-line react/no-unused-prop-types
    onEndDrag: PropTypes.func.isRequired, // eslint-disable-line react/no-unused-prop-types
    setComponentOption: PropTypes.func.isRequired,
    setContentOption: PropTypes.func.isRequired,
    mergeContentOption: PropTypes.func.isRequired,
    appendColumnComponents: PropTypes.func.isRequired,
    contentDidDrop: PropTypes.func.isRequired,
    renderTarget: PropTypes.string.isRequired,
    onResetContentItems: PropTypes.func.isRequired,
    setActiveSection: PropTypes.func.isRequired,
    isOver: PropTypes.bool.isRequired,
    didDrop: PropTypes.bool.isRequired,
    isSectionActive: PropTypes.bool.isRequired,
    itemType: PropTypes.string,
    className: PropTypes.string,
    isCropping: PropTypes.bool.isRequired,
  };

  static defaultProps = {
    dndSource: DND_CONTENT_MODULE,
    dndType: DND_CONTENT_MODULE,
    isContentLocked: false,
    isContentUnlockable: true,
    moduleOptions: moduleDefaultOptionsProps,
    itemType: null,
    className: '',
  };

  static renderDraghandle() {
    const draghandleIcon = 'c-bb-button c-bb-button--circle c-bb-section__drag';
    return (
      <div className={draghandleIcon}>
        <Icon name="move" medium />
      </div>
    );
  }

  constructor(props) {
    super(props);
    this.onMouseOver = this.onMouseOver.bind(this);
    this.onMouseLeave = this.onMouseLeave.bind(this);
    this.onClick = this.onClick.bind(this);
    this.onShowPopup = this.onShowPopup.bind(this);
    this.onHidePopup = this.onHidePopup.bind(this);
    this.onSectionSave = this.onSectionSave.bind(this);
    this.renderColumn = this.renderColumn.bind(this);
    this.onComponentContentChange = this.onComponentContentChange.bind(this);

    this.state = {
      isHovered: false,
      popupIsShown: false,
      toggleableComponentTypes: [],
    };
  }

  componentDidMount() {
    this.setToggleableComponentTypes(this.props);

    // Use an empty image as a drag preview so browsers don't draw it
    // and we can draw are own custom drag node layer.
    this.props.connectDragPreview(getEmptyImage(), {
      // IE CSS fallback
      captureDaggingState: true,
    });

    this.mergeDefaultModuleOptions();
  }

  componentDidUpdate(prevProps) {
    if (!isEqual(this.props, prevProps)) {
      this.setToggleableComponentTypes(this.props);
    }

    if (!this.props.isOver && !this.props.didDrop && prevProps.itemType && this.props.itemType === null) {
      this.props.onResetContentItems();
    }

    if (prevProps.dndIsDragging && !this.props.dndIsDragging) {
      this.props.contentDidDrop(this.props.content);
    }
  }

  onComponentContentChange(componentProps, newContent) {
    const { fromSectionOption } = componentProps;
    if (fromSectionOption) {
      this.props.setContentOption({
        option: fromSectionOption,
        value: newContent,
        contentId: this.props.content.id,
      });
    } else {
      this.props.setComponentOption({
        option: 'content',
        value: newContent,
        contentId: componentProps.id,
      });
    }
  }

  onMouseOver() {
    const {
      isLocked,
      renderTarget,
    } = this.props;
    if (!isLocked && renderTarget === DEFAULT_VIEW) {
      this.setState({ isHovered: true });
    }
  }

  onClick() {
    const { content } = this.props;

    this.props.setActiveSection(content.id);
  }

  onMouseLeave(event) {
    const type = event.target.tagName.toLowerCase();
    if (this.sectionRef && type !== 'select') {
      this.setState({
        isHovered: false,
      });
    }
  }

  onShowPopup() {
    this.setState({ popupIsShown: true });
  }

  onHidePopup() {
    this.setState({ popupIsShown: false });
  }

  onSectionSave() {
    this.props.openSaveSection(this.props.content);
  }

  setToggleableComponentTypes(props) {
    if (!props.content || !props.content.options) {
      this.setState({
        toggleableComponentTypes: [],
      });
      return;
    }

    const { content } = props;
    const toggleableTypes = pick(
      props.moduleOptions,
      ['title', 'subtitle', 'text'],
    );
    const toggleableComponentTypes = Object.keys(toggleableTypes)
      .map(opt => ({
        type: opt,
        enabled: typeof content.options[opt] !== 'undefined',
      }));

    this.setState({
      toggleableComponentTypes,
    });
  }

  mergeContentOption = (option, value) => {
    this.props.mergeContentOption({
      contentId: this.props.content.id,
      option,
      value,
    });
  };

  mergeDefaultModuleOptions = () => {
    const { module, content, id } = this.props;
    if (id < 0) {
      // options merge only required by old sections
      return;
    }

    // merge button system options in order to work on sections from v4.1.3
    const shouldMerge = [
      'buttonContent',
      'buttonSecondaryContent',
    ];
    const newKeys = difference(keys(module.options), keys(content.options));

    newKeys.forEach((key) => {
      if (shouldMerge.includes(key)) {
        this.mergeContentOption(key, module.options[key]);
      }
    });
    this.mergeColumnComponents(module, content);
  };

  mergeColumnComponents = (module, content) => {
    if (!module.columns) {
      return;
    }

    map(content.columns, (contentColumn, index) => {
      const column = module.columns[index] || module.columns[0];
      const componentKeys = difference(
        keys(column.components),
        keys(contentColumn.components),
      ).map(item => Number(item));

      if (!componentKeys.length) {
        return;
      }

      const newComponents = column.components
        .filter((component, i) => componentKeys.includes(i))
        .filter(component => ['button', 'buttonSecondary'].includes(component.type));

      this.props.appendColumnComponents({ column: contentColumn, newComponents });
    });
  };

  isDraggingNewSection = () => {
    const {
      dndSource,
      renderTarget,
    } = this.props;

    return [DND_MENU_MODULE, DND_MENU_SAVED_SECTION].includes(dndSource) && renderTarget === DEFAULT_VIEW;
  };

  isMovingSection = () => {
    const {
      dndIsDragging,
    } = this.props;

    return dndIsDragging;
  };

  renderTitle() {
    const { content: { options, id, type }, renderTarget } = this.props;
    const { title: content } = options;
    if (content) {
      const component = {
        type: CONTENT_COMPONENT_TYPE.title, content, id, fromSectionOption: 'title',
      };
      const containerClassName = containerClass(type, CONTENT_COMPONENT_TYPE.title);
      const Tag = ContentComponents[component.type];
      return (
        <Tag
          onChange={this.onComponentContentChange}
          containerClassName={containerClassName}
          renderTarget={renderTarget}
          {...component}
        />
      );
    }
    return null;
  }

  renderSubTitle() {
    const { content: { options, id, type }, renderTarget } = this.props;
    const { subtitle: content } = options;
    if (content) {
      const component = {
        type: CONTENT_COMPONENT_TYPE.subtitle, content, id, fromSectionOption: 'subtitle',
      };
      const containerClassName = containerClass(type, CONTENT_COMPONENT_TYPE.subtitle);
      const Tag = ContentComponents[component.type];
      return (
        <Tag
          onChange={this.onComponentContentChange}
          containerClassName={containerClassName}
          renderTarget={renderTarget}
          {...component}
        />
      );
    }
    return null;
  }

  renderButtons() {
    const {
      content: {
        options: {
          buttonDisplay,
        },
      },
    } = this.props;

    if (buttonDisplay && buttonDisplay !== BUTTON_MODE.BUTTON_PER_LAYOUT) {
      return null;
    }

    return (
      <>
        {this.renderPrimaryButton()}
        {this.renderSecondaryButton()}
      </>
    );
  }

  renderPrimaryButton() {
    const { content: { options: { primaryButtonsActive } } } = this.props;

    if (primaryButtonsActive) {
      return this.renderButtonFromSectionOption('buttonContent');
    }

    return null;
  }

  renderSecondaryButton() {
    const { content: { options: { secondaryButtonsActive } } } = this.props;

    if (secondaryButtonsActive) {
      return this.renderButtonFromSectionOption('buttonSecondaryContent');
    }

    return null;
  }

  renderButtonFromSectionOption(name) {
    const { content: { options, id, type }, renderTarget } = this.props;
    const buttonContent = options[name];

    if (buttonContent) {
      const component = {
        type: CONTENT_COMPONENT_TYPE.button, content: buttonContent, id, fromSectionOption: name,
      };
      const containerClassName = containerClass(type, CONTENT_COMPONENT_TYPE.button);
      const Tag = ContentComponents[component.type];
      return (
        <Tag
          onChange={this.onComponentContentChange}
          containerClassName={containerClassName}
          renderTarget={renderTarget}
          {...component}
        />
      );
    }

    return null;
  }

  renderText() {
    const { content: { options, id, type }, renderTarget } = this.props;
    const {
      text: content,
    } = options;
    if (content) {
      const component = {
        type: CONTENT_COMPONENT_TYPE.text, content, id, fromSectionOption: 'text',
      };
      const containerClassName = containerClass(type, CONTENT_COMPONENT_TYPE.text);
      const Tag = ContentComponents[component.type];
      return (
        <Tag
          onChange={this.onComponentContentChange}
          containerClassName={containerClassName}
          renderTarget={renderTarget}
          {...component}
        />
      );
    }
    return null;
  }

  renderColumn(column, idx, columns) {
    const columnCount = columns.filter(c => c.visible !== false).length;

    const {
      content,
      renderTarget,
      moduleOptions,
    } = this.props;

    const { options } = column;

    if (column.visible === false) {
      return null;
    }

    const classes = classNames(
      options.colorSchemeClass,
      { [`l-column--${options.size}`]: moduleOptions.columnPositioningActive && typeof options.size !== 'undefined' },
      { [`l-column--v-align-${options.verticalAlignment}`]: moduleOptions.columnAlignmentActive && options.verticalAlignment },
      { [`l-column--h-align-${options.horizontalAlignment}`]: moduleOptions.columnAlignmentActive && options.horizontalAlignment },
    );

    return (
      <BrowsboxContentColumn
        className={classes}
        key={idx}
        content={content}
        column={column}
        moduleOptions={moduleOptions}
        components={column.components}
        contentType={content.type}
        onChange={this.onComponentContentChange}
        renderTarget={renderTarget}
        isLast={idx === columnCount - 1}
      />
    );
  }

  renderSectionConfig() {
    const {
      dndIsDragging,
      connectDragSource,
      isLocked,
      isCropping,
      content,
      moduleOptions,
    } = this.props;

    const contentOptions = content.options || {};
    const { toggleableComponentTypes } = this.state;

    if (!this.state.isHovered && !this.state.popupIsShown) {
      return null;
    }

    if (isCropping) {
      return null;
    }

    const opacity = dndIsDragging ? 0.25 : 1;
    // const isDeletable = content.deletable !== false;
    const isDeletable = true;
    const currentColumnCount = content.type === CONTENT_SECTION_TYPE.gallery
      ? Number(contentOptions.columnCount) || DEFAULT_COLUMN_COUNT
      : content.columns.filter(c => c.visible !== false).length;

    const configAttributes = {
      backgroundFullWidth: Boolean(contentOptions.backgroundFullWidth),
      backgroundImage: contentOptions.backgroundImage,
      backgroundPosition: contentOptions.backgroundPosition,
      backgroundBehavior: contentOptions.backgroundBehavior,
      backgroundTransparency: Number(contentOptions.backgroundTransparency),
      backgroundScrolling: Boolean(contentOptions.backgroundScrolling),
      currentColumnCount,
      contentBackgroundImage: contentOptions.backgroundImage,
      contentColor: contentOptions.backgroundColor,
      contentColorSchemeClass: contentOptions.colorSchemeClass,
      contentId: content.id,
      contentType: content.type,
      maxColumnCount: Number(moduleOptions.maxColumnCount),
      marginsClasses: contentOptions.marginsClasses || '',
      isContentLocked: this.props.isContentLocked,
      isContentUnlockable: this.props.isContentUnlockable,
      isDeletable,
      moduleOptions,
      onHidePopup: this.onHidePopup,
      onShowPopup: this.onShowPopup,
      onSectionSave: this.onSectionSave,
      toggleableComponentTypes,
    };

    return (
      <div style={{ ...opacity }} className="c-bb-section-config">
        { !isLocked && <BrowsboxContentSectionConfig {...configAttributes} /> }
        { connectDragSource(BrowsboxContentItem.renderDraghandle()) }
      </div>
    );
  }

  renderSection(animationState, ref) {
    const {
      content,
      content: { options },
      dndIsDragging,
      renderTarget,
      isSectionActive,
      className,
      moduleOptions,
    } = this.props;

    const {
      isHovered,
      popupIsShown,
    } = this.state;

    const marginsClasses = get(options, 'marginsClasses', moduleOptions.marginsClasses);

    // Use general section or specific section (gallery, slider, ...)
    const Tag = ContentSections[content.type] || ContentSections.base;

    // Full content item
    const classes = classNames(
      'c-section',
      'c-bb-section',
      { 'o-content-module--dragging': dndIsDragging },
      { 'c-bb-section--editable': isHovered || popupIsShown },
      { 'c-bb-section--is-active': isSectionActive },
      { [`c-section--button-align-${options.buttonAlign}`]: options.buttonAlign },
      content.classes,
      options.colorSchemeClass,
      { [marginsClasses]: moduleOptions.marginsClasses !== null },
      options.layoutClasses,
      { 'c-section-animated': !!animationState },
      { [`c-section-animated--state-${animationState}`]: !!animationState },
      className,
    );

    const styles = {
      backgroundColor: content.options?.backgroundColor,
    };

    return (
      <section
        style={styles}
        className={classes}
        id={content.id}
        onMouseLeave={this.onMouseLeave}
        onMouseOver={this.onMouseOver}
        onClick={this.onClick}
        ref={(node) => {
          this.sectionRef = node;
          // eslint-disable-next-line no-param-reassign
          ref.current = node;
        }}
      >
        {/* eslint-disable-next-line react/jsx-no-constructed-context-values */}
        <ContentItemContext.Provider value={{ content }}>
          {renderTarget === DEFAULT_VIEW && this.renderSectionConfig()}
          <div className={classNames('c-section__background', { 'c-section__background--boxed': !options.backgroundFullWidth })}>
            <ContentItemBackground
              position={options.backgroundPosition}
              behavior={options.backgroundBehavior}
              transparency={Number(options.backgroundTransparency)}
              scrolling={Boolean(options.backgroundScrolling)}
              image={content.options?.backgroundImage}
            />
          </div>
          <Tag
            className="c-section__wrapper"
            content={content}
            title={() => this.renderTitle()}
            subTitle={() => this.renderSubTitle()}
            text={() => this.renderText()}
            button={() => this.renderButtons()}
            column={this.renderColumn}
            renderTarget={renderTarget}
          />
        </ContentItemContext.Provider>
      </section>
    );
  }

  renderSectionContents = (animationState, ref) => {
    const {
      connectDragSource,
      connectDropTarget,
      renderTarget,
    } = this.props;

    // Placeholder while dragging from menu to content
    if (this.isDraggingNewSection()) {
      return (
        connectDropTarget(
          <div className="c-bb-section__placeholder" ref={ref}>
            {i18n.t('CONTENT.dropBlockHere')}
          </div>,
        )
      );
    }

    // Placeholder while dragging between content items
    if (this.isMovingSection()) {
      return connectDragSource(connectDropTarget(
        <div className="c-bb-section__placeholder" ref={ref}>
          {i18n.t('CONTENT.dropBlockHere')}
        </div>,
      ));
    }

    // Render with full component edit behavior. While 'smart phone, tablet or desktop' preview
    // is active do not add component edit behavior.
    if (renderTarget === DEFAULT_VIEW) {
      return connectDropTarget(this.renderSection(animationState, ref));
    }

    return this.renderSection(undefined, ref);
  };

  render() {
    return (
      <AnimatedItemContext.Consumer>
        {({ state: parentState }) => {
          const itemRef = React.createRef();
          return (
            <Transition
              timeout={600}
              nodeRef={itemRef}
              in={
                parentState === 'entered'
                && !this.isDraggingNewSection()
                && !this.isMovingSection()
              }
            >
              {animationState => this.renderSectionContents(animationState, itemRef)}
            </Transition>
          );
        }}
      </AnimatedItemContext.Consumer>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { modules } = state.entities;
  const { lastAddedContentId } = state.content;
  const content = (state.entities.content || []).find(item => item.id === ownProps.id);
  const moduleDef = modules[ownProps.type] || {};
  const isSectionActive = state.content.activeSectionId === ownProps.id;
  const { isCropping } = state.ui;

  return {
    moduleOptions: moduleDef.options,
    module: moduleDef,
    content: content || ownProps.contentItem,
    isSectionActive,
    isCropping,
    lastAddedContentId,
  };
};

const mapDispatchToProps = {
  setContentOption,
  setComponentOption,
  appendColumnComponents,
  mergeContentOption,
  setActiveSection,
  contentDidDrop,
  openSaveSection,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(BrowsboxContentItem);
