import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
  contentColumnPropType,
  contentPropType,
  contentComponentPropType,
  CONTENT_SECTION_TYPE,
  moduleOptionsProps,
} from '@liswood-tache/browsbox-static';
import { ContentItemBackground } from '@liswood-tache/browsbox-static/lib/index.modern';
import { useDrag, useDrop } from 'react-dnd';
import { useDispatch } from 'react-redux';
import { DEFAULT_VIEW } from '../../actions/responsive';
import BrowsboxContentColumnConfig from './ContentColumnConfig';
import ContentColumnResizeHandle from './ContentColumnResizeHandle';
import { transposeColumns } from '../../actions/contentConfig';
import ContentInsertComponent from './BlockBuilderTools/ContentInsertComponent';
import { DND_BUILDER_TRANSPOSABLE_COLUMN } from '../DragAndDrop/dndTypes';
import SuperAdminInFooterBuilderOnly from './SuperAdminInFooterBuilderOnly';
import ContentComponent from './ContentComponent';
import ContentComponentDropArea from './ContentComponentDropArea';

const BrowsboxContentColumn = ({
  className,
  column,
  content,
  components,
  onChange,
  type,
  contentType,
  renderTarget,
  moduleOptions,
  isLast,
}) => {
  const columnRef = useRef();
  const dispatch = useDispatch();

  const [{ isBeingTransposed }, drag] = useDrag(() => ({
    type: DND_BUILDER_TRANSPOSABLE_COLUMN,
    item: () => {
      const rect = columnRef.current.getBoundingClientRect();

      return { id: column.id, width: rect.width, height: rect.height };
    },
    collect: (monitor) => ({
      item: monitor.getItem(),
      isBeingTransposed: monitor.isDragging(),
    }),
  }), [column]);

  const [{ isOver }, drop] = useDrop(() => ({
    accept: DND_BUILDER_TRANSPOSABLE_COLUMN,
    collect: (monitor) => ({
      isOver: monitor.isOver(),
    }),
    drop: (item) => {
      dispatch(transposeColumns({
        contentId: content.id,
        columnId: column.id,
        otherColumnId: item.id,
      }));
    },
  }), [content, column]);

  const [isConfigVisible, setIsConfigVisible] = useState(false);
  const [isPopupVisible, setIsPopupVisible] = useState(false);

  const handleMouseOver = () => setIsConfigVisible(true);
  const handleMouseLeave = () => setIsConfigVisible(false);
  const handleOnFocus = handleMouseOver;
  const handleOnBlur = handleMouseLeave;

  const handlePopupShow = () => setIsPopupVisible(true);
  const handlePopupHide = () => setIsPopupVisible(false);

  const { options } = column;

  const classes = classNames(
    'l-column',
    className,
    column.classes,
    { [`o-content-column-${type}`]: type },
  );

  if (column.enabled === false) {
    return null;
  }

  return (
    <div
      ref={(ref) => {
        drop(ref);
        columnRef.current = ref;
      }}
      className={classes}
      id={column.id}
      onMouseOver={handleMouseOver}
      onMouseLeave={handleMouseLeave}
      onFocus={handleOnFocus}
      onBlur={handleOnBlur}
      style={{ opacity: isBeingTransposed || isOver ? 0.25 : 1 }}
    >
      {moduleOptions.columnBackgroundActive && (
      <div className="l-column__background">
        <ContentItemBackground
          position={options.backgroundPosition}
          behavior={options.backgroundBehavior}
          transparency={Number(options.backgroundTransparency)}
          scrolling={Boolean(options.backgroundScrolling)}
          image={options.backgroundImage}
        />
      </div>
      )}

      <SuperAdminInFooterBuilderOnly contentType={contentType}>
        {(isConfigVisible || isPopupVisible) && (
        <BrowsboxContentColumnConfig
          columnId={column.id}
          contentType={content.type}
          contentId={content.id}
          columnAlignmentActive={Boolean(moduleOptions.columnAlignmentActive)}
          columnBackgroundActive={Boolean(moduleOptions.columnBackgroundActive)}
          columnPositioningActive={Boolean(moduleOptions.columnPositioningActive)}
          contentColor={options.backgroundColor}
          contentColorSchemeClass={options.colorSchemeClass}
          backgroundImage={options.backgroundImage}
          backgroundPosition={options.backgroundPosition}
          backgroundBehavior={options.backgroundBehavior}
          backgroundScrolling={Boolean(options.backgroundScrolling)}
          backgroundTransparency={Number(options.backgroundTransparency)}
          verticalAlignment={options.verticalAlignment || ''}
          horizontalAlignment={options.horizontalAlignment || ''}
          columnDragRef={drag}
          onPopupShow={handlePopupShow}
          onPopupHide={handlePopupHide}
        />
        )}

        {renderTarget === DEFAULT_VIEW
            && [CONTENT_SECTION_TYPE.blockBuilder, CONTENT_SECTION_TYPE.footerBuilder].includes(content.type)
            && (
              <ContentInsertComponent
                contentType={content.type}
                contentId={content.id}
                columnId={column.id}
              />
            )}
      </SuperAdminInFooterBuilderOnly>

      {components.map((component, index) => (
        <ContentComponent
          key={component.id}
          content={content}
          component={component}
          column={column}
          onChange={onChange}
          contentId={content.id}
          contentType={contentType}
          renderTarget={renderTarget}
          type={type}
          index={index}
        />
      ))}

      <SuperAdminInFooterBuilderOnly contentType={contentType}>
        {renderTarget === DEFAULT_VIEW && !isLast && moduleOptions.columnPositioningActive && (
        <ContentColumnResizeHandle columnId={column.id} />
        )}

        {renderTarget === DEFAULT_VIEW
            && [CONTENT_SECTION_TYPE.blockBuilder, CONTENT_SECTION_TYPE.footerBuilder].includes(content.type)
            && (
              <ContentComponentDropArea
                content={content}
                column={column}
              />
            )}
      </SuperAdminInFooterBuilderOnly>
    </div>
  );
};

BrowsboxContentColumn.propTypes = {
  className: PropTypes.string,
  content: contentPropType.isRequired,
  column: contentColumnPropType.isRequired,
  components: PropTypes.arrayOf(contentComponentPropType),
  onChange: PropTypes.func.isRequired,
  type: PropTypes.string, // Override component.type to determine ContentComponents to add
  contentType: PropTypes.string,
  renderTarget: PropTypes.string.isRequired,
  moduleOptions: moduleOptionsProps,
  isLast: PropTypes.bool,
};

BrowsboxContentColumn.defaultProps = {
  className: '',
  components: [],
  type: '',
  contentType: '',
  moduleOptions: {},
  isLast: false,
};

export default BrowsboxContentColumn;
