import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import focusScope from 'a11y-focus-scope';
import Tether from 'tether';

const defaultTetherConfig = {
  classPrefix: 'bb-tether',
  enabled: false,
  classes: {
    element: false,
    enabled: 'show',
  },
  constraints: [
    { to: 'scrollParent', attachment: 'together none' },
    { to: 'window', attachment: 'together none' },
  ],
};

const getTetherAttachments = (placement) => {
  let attachments = {};
  switch (placement) {
    case 'top':
    case 'top center':
      attachments = {
        attachment: 'bottom center',
        targetAttachment: 'top center',
      };
      break;
    case 'bottom':
    case 'bottom center':
      attachments = {
        attachment: 'top center',
        targetAttachment: 'bottom center',
      };
      break;
    case 'left':
    case 'left center':
      attachments = {
        attachment: 'middle right',
        targetAttachment: 'middle left',
      };
      break;
    case 'right':
    case 'right center':
      attachments = {
        attachment: 'middle left',
        targetAttachment: 'middle right',
      };
      break;
    case 'top left':
      attachments = {
        attachment: 'bottom left',
        targetAttachment: 'top left',
      };
      break;
    case 'top right':
      attachments = {
        attachment: 'bottom right',
        targetAttachment: 'top right',
      };
      break;
    case 'bottom left':
      attachments = {
        attachment: 'top left',
        targetAttachment: 'bottom left',
      };
      break;
    case 'bottom right':
      attachments = {
        attachment: 'top right',
        targetAttachment: 'bottom right',
      };
      break;
    case 'right top':
      attachments = {
        attachment: 'top left',
        targetAttachment: 'top right',
      };
      break;
    case 'right bottom':
      attachments = {
        attachment: 'bottom left',
        targetAttachment: 'bottom right',
      };
      break;
    case 'left top':
      attachments = {
        attachment: 'top right',
        targetAttachment: 'top left',
      };
      break;
    case 'left bottom':
      attachments = {
        attachment: 'bottom right',
        targetAttachment: 'bottom left',
      };
      break;
    default:
      attachments = {
        attachment: 'top center',
        targetAttachment: 'bottom center',
      };
  }

  return attachments;
};

export const tetherAttachements = [
  'top',
  'bottom',
  'left',
  'right',
  'top left',
  'top center',
  'top right',
  'right top',
  'right middle',
  'right bottom',
  'bottom right',
  'bottom center',
  'bottom left',
  'left top',
  'left middle',
  'left bottom',
];

const propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  closeOnClickInside: PropTypes.bool,
  closeOnEsc: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  placement: PropTypes.oneOf(tetherAttachements), // eslint-disable-line react/no-unused-prop-types
  style: PropTypes.object, // eslint-disable-line
  target: PropTypes.string.isRequired,
  tether: PropTypes.object, // eslint-disable-line
  // A popup within a modal could cause issues with both trying to get focus, solve by not focusing
  // the popup within the modal
  focus: PropTypes.bool,
  onMouseOver: PropTypes.func,
  onMouseLeave: PropTypes.func,
};

const defaultProps = {
  children: null,
  className: '',
  closeOnClickInside: false,
  closeOnEsc: true,
  placement: 'bottom',
  style: {},
  tether: {},
  focus: true,
  onMouseOver: () => {},
  onMouseLeave: () => {},
};

const setFocusOn = (applicationElement, element) => {
  // focusStore.storeFocus();
  if (applicationElement) {
    applicationElement.setAttribute('aria-hidden', 'true');
  }
  focusScope.scopeFocus(element);
};

const resetFocus = (applicationElement) => {
  focusScope.unscopeFocus();
  if (applicationElement) {
    applicationElement.removeAttribute('aria-hidden');
  }
  // focusStore.restoreFocus();
};

let clickInsidePopup = false;
let rafOnDocumentClick = false;

class bbPopup extends Component {
  // eslint-disable-next-line
  static getApplicationElement() {
    // eslint-disable-next-line
    console.warn('`bbPopup.getApplicationElement` needs to be set for accessibility reasons');
  }

  static handlePopupClick(event) {
    event.stopPropagation();
    clickInsidePopup = true;
  }

  static getTetherConfig(props) {
    const attachments = getTetherAttachments(props.placement);
    return {
      ...defaultTetherConfig,
      ...attachments,
      target: `#${props.target}`,
      element: `#${props.target}-popup`,
      ...props.tether,
    };
  }

  constructor(props) {
    super(props);
    this.handleDocumentKeydown = this.handleDocumentKeydown.bind(this);
    this.handleDocumentClick = this.handleDocumentClick.bind(this);
  }

  componentDidMount() {
    document.addEventListener('keydown', this.handleDocumentKeydown, true);
    document.addEventListener('click', this.handleDocumentClick, true);
    const config = bbPopup.getTetherConfig(this.props);
    this.tether = new Tether(config);
    this.tether.enable();
    this.tether.position();
    if (this.props.focus) {
      setFocusOn(bbPopup.getApplicationElement(), this.popup);
    }
  }

  shouldComponentUpdate(nextProps) {
    if (this.props.target !== nextProps.target) {
      this.rafOnUpdate = window.requestAnimationFrame(() => {
        const config = bbPopup.getTetherConfig(nextProps);
        this.tether = new Tether(config);
        this.tether.enable();
        this.tether.position();
        if (this.props.focus) {
          setFocusOn(bbPopup.getApplicationElement(), this.popup);
        }
        this.rafOnUpdate = false;
      });
      return true;
    }

    return this.props.children !== nextProps.children;
  }

  componentDidCatch(error, info) {
    // eslint-disable-next-line no-console
    console.error('Unhandled error in popup', error, info);
    this.props.onClose();
  }

  componentWillUnmount() {
    if (this.props.focus) {
      resetFocus(bbPopup.getApplicationElement());
    }
    document.removeEventListener('keydown', this.handleDocumentKeydown, true);
    document.removeEventListener('click', this.handleDocumentClick, true);
    if (rafOnDocumentClick !== false) {
      window.cancelAnimationFrame(rafOnDocumentClick);
    }
    if (this.rafOnUpdate !== false) {
      window.cancelAnimationFrame(this.rafOnUpdate);
    }

    this.destroyTether();
  }

  handleDocumentClick(event) {
    // Use requestAnimationFrame because document click handler is called before
    // click inside popup is called.
    clickInsidePopup = false; // handlePopupClick will set this to true
    rafOnDocumentClick = window.requestAnimationFrame(() => {
      const { closeOnClickInside } = this.props;
      if (!clickInsidePopup || (closeOnClickInside === true && clickInsidePopup)) {
        this.props.onClose(event);
      }
      rafOnDocumentClick = false;
    });
  }

  handleDocumentKeydown(event) {
    if (this.props.closeOnEsc && event.keyCode === 27) {
      this.props.onClose(event);
    }
  }

  destroyTether() {
    if (this.tether && this.tether.destroy) {
      this.tether.destroy();
      this.tether = null;
    }
  }

  render() {
    const classes = classNames(
      this.props.className,
      'o-bb-popup',
    );
    return (
      <div
        ref={(i) => { this.popup = i; }}
        className={classes}
        style={this.props.style}
        onClick={bbPopup.handlePopupClick}
        onMouseOver={this.props.onMouseOver}
        onMouseLeave={this.props.onMouseLeave}
        onFocus={this.props.onMouseOver}
        tabIndex="-1"
        id={`${this.props.target}-popup`}
      >
        <div className="o-bb-popup__wrapper">
          {this.props.children}
        </div>
      </div>
    );
  }
}

bbPopup.propTypes = propTypes;
bbPopup.defaultProps = defaultProps;

export default bbPopup;
