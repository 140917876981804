import { useDispatch } from 'react-redux';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDrag } from 'react-dnd';
import { DND_MENU_SAVED_SECTION } from '../DragAndDrop/dndTypes';
import { addContent } from '../../actions/contentAdd';
import { openRemoveSavedSection, openRenameSavedSection } from '../../actions/savedSections';
import { Gateway } from '../Gateway';
import Icon from '../Icon/Icon';
import BrowsboxSavedSectionsConfigMenu from './BrowsboxSavedSectionsConfigMenu';
import { getNewId } from '../../utils/entities';

const BrowsboxSavedSectionsItem = ({ savedSection }) => {
  const dispatch = useDispatch();

  const [showPopup, setShowPopup] = useState(false);

  const [{ clientOffset, isDragging }, dragRef] = useDrag({
    type: DND_MENU_SAVED_SECTION,
    collect: monitor => ({
      isDragging: monitor.isDragging(),
      clientOffset: monitor.getClientOffset(),
    }),
    end: (props, monitor) => {
      const item = monitor.getItem();
      const droppedIntoContent = monitor.getDropResult() && monitor.getDropResult().isDroppedIntoContent;

      if (typeof item.index !== 'undefined' && droppedIntoContent) {
        dispatch(addContent({
          ...item,
          classes: savedSection.classes,
          options: savedSection.options,
          columns: savedSection.savedColumns,
        }));
      }
    },
    item: {
      id: getNewId(),
      type: savedSection.type,
      dndSource: DND_MENU_SAVED_SECTION,
      dndType: DND_MENU_SAVED_SECTION,
    },
  });

  const handleChangeNameClick = () => dispatch(openRenameSavedSection({
    id: savedSection.id,
    name: savedSection.name,
  }));

  const handleRemoveClick = () => dispatch(openRemoveSavedSection({
    id: savedSection.id,
  }));

  const tetherTarget = `saved-section-${savedSection.id}`;

  return (
    <>
      {isDragging && clientOffset && (
        <Gateway into="popup">
          <div
            className="c-bb-saved-section-item__drag-preview"
            style={{
              position: 'fixed', top: 0, right: 0, bottom: 0, left: 0, zIndex: 9999999,
            }}
          >
            <div
              className="c-bb-saved-section-item c-bb-saved-section-item__drag-preview__item is-dragging"
              style={{
                transform: `translate(calc(-50% + ${clientOffset.x}px), calc(-50% + ${clientOffset.y}px))`,
              }}
            >
              <img className="c-bb-saved-section-item__icon" src={savedSection.img} alt="" />
              <span className="c-bb-saved-section-item__title">{savedSection.name}</span>
            </div>
          </div>
        </Gateway>
      )}
      <div
        className="c-bb-saved-section-item"
        ref={dragRef}
        style={{ opacity: isDragging ? 0.25 : 1 }}
        title={savedSection.name}
      >
        <img className="c-bb-saved-section-item__icon" src={savedSection.img} alt="" />
        <span className="c-bb-saved-section-item__title">{savedSection.name}</span>
        <Icon className="c-bb-saved-section-item__options-button" name="context-menu" onClick={() => setShowPopup(true)} id={tetherTarget} />
        {
            showPopup && (
              <BrowsboxSavedSectionsConfigMenu
                tetherTarget={tetherTarget}
                onClose={() => setShowPopup(false)}
                onChangeNameClick={handleChangeNameClick}
                onRemoveClick={handleRemoveClick}
              />
            )
          }
      </div>
    </>
  );
};

BrowsboxSavedSectionsItem.propTypes = {
  savedSection: PropTypes.object.isRequired,
};

export default BrowsboxSavedSectionsItem;
