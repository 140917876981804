import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import {
  shallowEqual, useDispatch, useSelector,
} from 'react-redux';
import { withNamespaces } from 'react-i18next';
import { editorUpdate } from '../../../actions/contentEditor';
import { Gateway } from '../../Gateway';
import { BbPopup } from '../../@browsbox-ui';
import { insertComponentToColumn } from '../../../actions/contentAdd';

const ContentComponentPopup = withNamespaces()(({
  tetherTarget, contentType, onClick: handleClick, onClose, t,
}) => {
  const allComponents = useSelector(
    (state) => Object.values(state.entities.components || {}),
    shallowEqual,
  );

  const components = useMemo(
    () => allComponents.filter(({ blocks }) => blocks.length === 0 || blocks.includes(contentType)),
    [allComponents],
  );

  const onClick = (type) => (e) => {
    e.preventDefault();
    handleClick(type);
  };

  return (
    <Gateway into="popup">
      <BbPopup onClose={onClose} target={tetherTarget}>
        <div className="c-component-list">
          {components.map(({ type, img }) => (
            <div key={type} className="c-component-list__item">
              <a href="" className="c-component-item" onClick={onClick(type)}>
                <img className="c-component-item__icon" src={img} alt={type} />
                <span className="c-component-item__title">{t(`COMPONENTS.${type}`)}</span>
              </a>
            </div>
          ))}
        </div>
      </BbPopup>
    </Gateway>
  );
});

ContentComponentPopup.propTypes = {
  tetherTarget: PropTypes.string.isRequired,
  contentType: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
};

const ContentInsertComponent = ({
  contentType,
  columnId,
  contentId,
  componentId,
}) => {
  const dispatch = useDispatch();
  const [isPopupShown, setIsPopupShown] = useState(false);

  const showPicker = (e) => {
    e.preventDefault();

    setIsPopupShown(true);
  };

  const hidePicker = () => {
    setIsPopupShown(false);
  };

  const handleOnClick = (type) => {
    hidePicker();

    dispatch(
      insertComponentToColumn({
        componentId,
        contentId,
        columnId,
        type,
      }),
    );

    dispatch(editorUpdate());
  };

  const tetherTarget = `component-${contentId}-${columnId}-${componentId || 'empty'}`;

  return (
    <div className="c-insert-component">
      <a href="" className="c-insert-component__button" onClick={showPicker} id={tetherTarget}>
        <span className="fa fa-plus-circle" />
      </a>
      {isPopupShown && (
        <ContentComponentPopup
          tetherTarget={tetherTarget}
          contentType={contentType}
          onClick={handleOnClick}
          onClose={hidePicker}
        />
      )}
    </div>
  );
};

ContentInsertComponent.propTypes = {
  contentType: PropTypes.string.isRequired,
  contentId: PropTypes.number.isRequired,
  columnId: PropTypes.number.isRequired,
  componentId: PropTypes.number,
};

ContentInsertComponent.defaultProps = {
  componentId: null,
};

export default ContentInsertComponent;
